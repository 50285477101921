<template>
    <div class="error-wrap">
		<img src="@/assets/images/index/sys-error.png" />
        <!-- <i class="iconfont icon404"></i> -->
        <p>{{siteInfo.close_reason || '抱歉！系统发生异常！'}}</p>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    name: "closed",
    created() {
        this.$store.dispatch("site/siteInfo")
    },
    computed: {
        ...mapGetters(["siteInfo"])
    }
}
</script>
<style lang="scss" scoped>
.error-wrap {
    text-align: center;
    padding: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    i {
        font-size: 60px;
    }
    p {
        margin: 20px 0 10px;
        font-size: 14px;
    }
}
</style>
